import React from "react"
import { graphql } from "gatsby"
import { mapDynamicComponent } from "./page"
import Layout from "../components/UI/Layout"

const Page = ({ data }) => {
  const { name, catchphrase, sections, path, locale } = data.product

  return (
    <>
      <Layout
        path={path}
        title={name}
        description={catchphrase}
        locale={locale}
      >
        {sections.map((section, index) =>
          mapDynamicComponent(section, index, locale)
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query ProductQuery($id: String) {
    product: strapiProduct(id: { eq: $id }) {
      name
      catchphrase
      path
      locale
      sections {
        ... on STRAPI__COMPONENT_PAGE_FEATURES {
          id
          title {
            content
            secondary
            level
          }
          features {
            label
            name
            featureDescription {
              data {
                featureDescription
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_BUTTON {
          id
          label
          url
          variant
          align
        }
        ... on STRAPI__COMPONENT_UI_IMAGE_GALLERY {
          id
          image {
            ext
            alternativeText
            url
          }
        }
        ... on STRAPI__COMPONENT_UI_PAGE_GALLERY {
          id
          pageLayout: layout
          pages {
            cover {
              alternativeText
              ext
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
            path
            locale
            title
            updatedAt
            description
            id
          }
          id
          productLayouts: layout
          products {
            name
            path
            catchphrase
            locale
            updatedAt
            catchphrases {
              data {
                catchphrases
              }
            }
            photos {
              ext
              alternativeText
              url
              formats {
                large {
                  url
                }
                medium {
                  url
                }
                small {
                  url
                }
                thumbnail {
                  url
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_PHOTO_GALLERY {
          id
          photos {
            alternativeText
            ext
            url
            caption
            formats {
              large {
                url
              }
              medium {
                url
              }
              small {
                url
              }
              thumbnail {
                url
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_SPACER {
          id
          size
        }
        ... on STRAPI__COMPONENT_UI_STEPS {
          id
          Block {
            Number
            title
            description {
              children {
                text
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_TEXT {
          id
          lessWide
          text {
            data {
              text
            }
          }
        }
        ... on STRAPI__COMPONENT_UI_TEXT_PHOTO {
          id
          textPhotoText: text {
            text {
              data {
                text
              }
            }
          }
          title {
            content
            level
            secondary
          }
          images {
            url
            ext
          }
          buttons {
            id
            label
            url
            variant
            align
          }
          layout
          Background
        }
        ... on STRAPI__COMPONENT_UI_TITLE {
          id
          content
          secondary
          level
          lessWide
        }
      }
    }
  }
`
export default Page
